.mmsi-stats {
  padding-top: 80px;

  &__col {
    &:nth-child(n+4) {
      margin-top: 30px;
    }

    @include media-breakpoint-down(md) {
      &:nth-child(n+3) {
        margin-top: 30px;
      }
    }

    .h2 {
      font-size: 82px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.34;

      @include media-breakpoint-down(lg) {
        font-size: 64px;
      }

      @include media-breakpoint-down(md) {
        font-size: 28px;
      }
    }

    p {
      font-size: 24px;
      line-height: 1.4;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }
    }
  }
}
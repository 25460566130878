* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $f-brown;
  font-weight: 400;
  color: $c-black;
  font-size: 18px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: $f-brown;
  font-weight: 300;
}

picture {
  display: flex;
}

img {
  max-width: 100%;
}

.container {
  @include media-breakpoint-up(xxxl) {
    max-width: calc(1410px + var(--bs-gutter-x, .75rem) * 2);
  }

  &-wide {
    @media (min-width: 1590px) {
      max-width: 1590px;
    }
  }
}

.h2 {
  font-weight: 300;
  font-size: 52px;
  line-height: 1.19;

  @include media-breakpoint-down(xl) {
    font-size: 42px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
}

.h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
  line-height: 1.78;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.link {
  &-arrow {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;

    span {
      width: 27px;
      height: 14px;
      margin-left: 10px;
      transition: .3s ease;
      position: relative;
      display: inline-flex;

      &:before {
        content: "";
        height: 100%;
        position: absolute;
        transition: .3s ease;
        min-width: 27px;
        max-width: 0;
        background-position: right;
        display: block;
        right: 0;
      }
    }

    &:hover {
      span {
        transform: translateX(5px);

        &:before {
          min-width: 22px;
        }
      }
    }
  }

  &-inherit {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: .15em;
    transition: .3s ease;

    &:hover {
      text-decoration-color: inherit;
    }

    &.ot-sdk-show-settings {
      color: $c-green!important;
      padding: 0!important;
      margin: 0!important;
      line-height: normal!important;
      font-size: 16px!important;
      background: none!important;
      border: none!important;
      font-family: $f-brown!important;
    }
  }

  &-orange {
    color: $c-orange;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: .15em;
    transition: .3s ease;

    &:hover {
      text-decoration-color: $c-orange;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.c {
  &-blue {
    color: $c-blue;
  }

  &-green {
    color: $c-green;
  }

  &-orange {
    color: $c-orange;
  }

  &-slate {
    color: $c-slate;
  }
}

.c-slate {

}

.btn {
  border-radius: 50px;
  border: 2px solid $c-orange-1;
  text-decoration: none;
  text-transform: uppercase;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  color: $c-orange-ada;
  cursor: pointer;
  background-color: transparent;
  letter-spacing: 1px;

  span {
    width: 27px;
    height: 14px;
    margin-left: 10px;
    transition: .3s ease;
    position: relative;

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      transition: .3s ease;
      min-width: 27px;
      max-width: 0;
      background-position: right;
      display: block;
      right: 0;
      background-image: url("/images/icon-arrow-right-cb6a4a.svg");
    }
  }

  &:hover {
    span {
      transform: translateX(5px);

      &:before {
        min-width: 22px;
      }
    }
  }
}

.parallax-parent {
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;

  &-img {
    height: 200%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
  }

  &-container {
    position: absolute;
    overflow: hidden;
  }
}

#js-contact-us-selectmenu {
  display: block!important;
  max-width: 0;
  max-height: 0;
  opacity: 0;
  border: none;
}

.parallax-copy {
  position: absolute;
  font-size: 200px;
  color: $c-slate;
  opacity: .08;
  line-height: .7;
  right: 0;
  top: 0;
  z-index: -2;
  font-weight: 700;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xxxl) {
    font-size: 175px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 150px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 100px;
  }

  @include media-breakpoint-down(md) {
    opacity: 0;
    visibility: hidden;
  }

  span {
    position: relative;
    left: 200px;
  }
}

.parallax-rotated {
  height: 400px;

  @include media-breakpoint-down(xl) {
    height: 250px;
  }

  @include media-breakpoint-down(lg) {
    height: 150px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    transform: unset !important;
    height: auto;
    margin-bottom: 20px;
  }

  .fade-up {
    @include media-breakpoint-up(md) {
      width: 0;
    }
  }
}

.rotated {
  display: flex;
  color: $c-slate;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    transform: rotate(-90deg);
  }
}

form {
  width: 100%;

  fieldset {
    border: none;
    width: 100%;

    label {
      display: none;
    }
  }

  input {
    font-size: 18px;
    padding: 0 15px;
    color: $c-black;
    font-family: $f-brown;

    &::placeholder {
      color: $c-black;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }

    &[type=text] {
      background-color: transparent;
      border-left: none;
      border-top: none;
      border-right: none;
      border-width: 1px;
      border-radius: 0;
      border-color: $c-white;
      height: 45px;
      font-size: 26px;
      line-height: 1;
      color: $c-white;
      font-weight: 300;
      margin-bottom: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0;

      @include media-breakpoint-down(md) {
        font-size: 18px;
      }

      &::placeholder {
        color: $c-white;
        opacity: .5;
      }

      &.error {
        border-color: $c-error;
      }
    }
  }

  label {
    &.error {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      color: $c-error;
      margin-top: -38px;
      margin-bottom: 24px;
      width: 100%;
    }
  }

  textarea {
    background-color: transparent;
    width: 100%;
    max-width: 100%;
    border: 1px solid $c-white;
    padding: 20px;
    font-size: 24px;
    line-height: 1.42;
    color: $c-white;
    font-family: $f-brown;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    &::placeholder {
      color: $c-white;
      opacity: .5;
    }
  }
}

.error {
  .ui-selectmenu-button {
    &.ui-button {
      border-bottom-color: $c-error;
    }
  }
}

.ui-selectmenu-button {
  &.ui-button {
    position: relative;
    background-color: transparent;
    width: 100%;
    color: $c-white;
    font-family: $f-brown;
    font-weight: 300;
    font-size: 26px;
    line-height: 1.38;
    height: 50px;
    padding: 0 15px 0 0;
    align-items: center;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    margin-bottom: 50px;
    display: grid;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    &.placeholder {
      .ui-selectmenu-text {
        opacity: .5;
      }
    }
  }

  &.ui-selectmenu-button-open {
    .ui-selectmenu-icon {
      transform: translateY(-50%) scaleY(1);
    }
  }

  .ui-selectmenu-icon {
    background-image: url("/images/icon-arrow.svg")!important;
    background-position: center;
    background-size: cover;
    width: 14px;
    height: 9px;
    float: none;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) scaleY(-1);
    transition: .3s ease;
  }
}

.ui-menu {
  &-item {
    &-wrapper {
      font-size: 16px;
      line-height: 1.62;
      letter-spacing: -0.01em;
      font-family: $f-brown;
      color: $c-black;
      border: none;

      &.ui-state-active {
        color: $c-black;
        background: linear-gradient(0deg, #DADBDE, #DADBDE), #FFFFFF;
        border: none;
        margin: 0;
      }
    }
  }
}

.checkbox {
  display: flex;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    border: 1px solid $c-gray-1;
    transform: translateY(-50%);
    transition: .3s ease;

    &:after {
      content: "";
      position: absolute;
      display: block;
      opacity: 0;
      visibility: hidden;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
      background-color: $c-orange;
      border-radius: 50%;
      transition: .3s ease;
    }
  }

  .checkbox-copy {
    transition: .3s ease;
  }
}

.iframe-container {
  padding: 56.25% 0 0 0;
  position: relative;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// reCAPTCHA v3
.grecaptcha-badge {visibility:hidden!important;}
.mmsi-video {
  margin-top: 50px;
  padding-top: 50px;

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }

  &__col {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}
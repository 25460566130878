.mmsi-bdst {
  margin-top: 30px;
  padding-top: 50px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  &-row {
    &-bot {
      margin-top: 30px;
      padding-top: 50px;

      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
    }
  }

  &__col {
    &-image {
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }

      img {
        max-width: 450px;
        width: 100%;
        border-radius: 24px;
        box-shadow: 10px 10px 60px 0 rgba(0, 0, 0, 0.15);

        @include media-breakpoint-down(md) {
          max-width: 100%;
        }
      }
    }
  }


  .h2 {
    max-width: 500px;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      max-width: 290px;
    }
  }

  p {
    margin-bottom: 30px;
  }
}
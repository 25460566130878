.mmsi-pd {
  margin-top: 100px;
  padding-top: 50px;
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    margin-top: 90px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 50px;
  }

  .row {
    &:not(:last-of-type) {
      margin-bottom: 100px;

      @include media-breakpoint-down(md) {
        margin-bottom: 70px;
      }
    }
  }

  &-humana {
    .mmsi-pd__col {
      &-left {
        img {
          aspect-ratio: 148/29;
          height: 29px;
        }
      }
    }
  }

  &-centerwell {
    .mmsi-pd__col {
      &-left {
        img {
          aspect-ratio: 172/47;
          height: 47px;
        }
      }
    }
  }

  &-kindred {
    .mmsi-pd__col {
      &-left {
        img {
          aspect-ratio: 155/67;
          height: 67px;
        }
      }
    }
  }

  &-lifepoint {
    .mmsi-pd__col {
      &-left {
        img {
          aspect-ratio: 151/40;
          height: 53px;
        }
      }
    }
  }

  &__col {
    &-left {
      h2 {
        display: none;
      }

      img {
        margin-bottom: 30px;
      }

      .h3 {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }

      ul {
        margin-left: 18px;

        li {
          font-size: 16px;
          line-height: 1.62;

          &:not(:last-of-type) {
            margin-bottom: 15px;
          }
        }
      }
    }

    &-right {
      padding: 0;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }

      img {
        aspect-ratio: 825/464;
        max-width: 825px;
      }
    }
  }
}
.mmsi-experience {
  margin-top: 100px;
  padding-top: 50px;

  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }

  .h2 {
    text-align: center;
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
      text-align: left;
    }
  }

  picture {
    display: flex;
    align-items: center;
    max-width: 1179px;
    width: 100%;
    aspect-ratio: 393/199;

    @include media-breakpoint-down(md) {
      max-width: 289px;
      aspect-ratio: 289/993;
    }

    img {
      width: 100%;
    }
  }
}
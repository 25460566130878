.mmsi-hero {
  height: 960px;
  position: relative;

  @include media-breakpoint-down(md) {
    height: 500px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 500px;
    left: 0;
    bottom: -1px;
    background-image: url("/images/hero-wave.svg");
    z-index: -1;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(twok) {
      height: 762px;
    }

    @include media-breakpoint-down(onek) {
      height: 500px;
    }

    @include media-breakpoint-down(xxl) {
      height: 426px;
    }

    @include media-breakpoint-down(lg) {
      height: 350px;
    }

    @include media-breakpoint-down(md) {
      height: 200px;
    }

    @include media-breakpoint-down(sm) {
      height: 150px;
    }

    @include media-breakpoint-down(tiny) {
      height: 100px;
    }
  }

  .parallax-parent {
    &-img {
      position: absolute;
      bottom: 13vw;
      width: 100%;
      height: 1358px;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 1025/679;

      @include media-breakpoint-down(onek) {
        bottom: 28%;
      }

      @include media-breakpoint-down(md) {
        height: 586px;
        bottom: 44%;
      }
    }

    &-container {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -2;
    }
  }

  .h1 {
    color: $c-white;
    font-size: 68px;
    font-weight: 400;
    max-width: 900px;
    padding-bottom: 300px;

    @include media-breakpoint-down(onek) {
      padding-bottom: 275px;
    }

    @include media-breakpoint-down(lg) {
      font-size: 56px;
    }

    @include media-breakpoint-down(md) {
      font-size: 30px;
      padding-bottom: 150px;
    }

    span {
      font-weight: 400;
      font-style: italic;
    }
  }

  .h2 {
    color: $c-white;
    font-weight: 300;
    font-size: 40px;
    max-width: 550px;

    @include media-breakpoint-down(md) {
      font-size: 22px;
      max-width: 290px;
    }
  }
}
.mmsi-image {
  position: relative;
  height: 960px;
  overflow: hidden;
  margin-top: 25px;
  padding-top: 50px;

  @include media-breakpoint-down(lg) {
    margin-top: 100px;
    padding-top: 50px;
    height: 65vw;
  }

  @include media-breakpoint-down(md) {
    margin-top: 20px;
    padding-top: 50px;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 12.5vw;
    background-image: url("/images/dashboard-wave.svg");
    background-size: cover;
    z-index: 1;
  }

  img {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: 50px;
    object-fit: cover;
    object-position: center;
  }
}
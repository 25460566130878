.mmsi-technology {
  padding-top: 50px;
  margin-top: 30px;

  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  .h2 {
    max-width: 600px;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
      max-width: 500px;
    }

    @include media-breakpoint-down(md) {
      max-width: 450px;
    }

    sup {
      position: relative;
      line-height: 0;
      top: 0;
      font-size: 50%;
    }
  }

  p {
    margin-bottom: 30px;
  }

  ul {
    margin: 0 0 30px 18px;

    li {
      font-size: 16px;
      line-height: 1.62;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }

  &__col {
    &-video {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        padding: 0;
      }

      img {
        aspect-ratio: 961/537;
        max-width: 961px;
        width: 100%;
      }
    }
  }
}
.mmsi-footer {
  background-color: $c-super-dark-blue;
  padding: 50px 0;

  img {
    display: flex;
    max-width: 137px;
    aspect-ratio: 137/60;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;

    li {
      &:not(:last-of-type) {
        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
        }
      }

      a,
      p {
        color: $c-green;
        font-size: 16px;
        line-height: 1;
        margin: 0;
      }
    }
  }
}
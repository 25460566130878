.mmsi-partners {
  margin-top: 100px;
  padding-top: 50px;

  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }

  .h2 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 30px;
  }

  picture {
    display: flex;
    align-items: center;
    max-width: 1196px;
    aspect-ratio: 2392/785;
    width: 100%;

    @include media-breakpoint-down(md) {
      max-width: 289px;
      aspect-ratio: 289/729;
    }

    img {
      width: 100%;
    }
  }
}
@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-Light.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-LightItalic.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-Regular.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-Italic.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-Bold.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'BrownLLWeb';
  src:url('../fonts/BrownLLWeb-BoldItalic.woff2') format('woff2'),
  url('../fonts/BrownLLWeb-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
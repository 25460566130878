.mmsi-experts {
  position: relative;
  overflow: hidden;
  padding: 350px 0 175px;

  @include media-breakpoint-down(onek) {
    padding: 300px 0 175px;
  }

  @include media-breakpoint-down(xxxl) {
    padding: 225px 0 150px;
  }

  @include media-breakpoint-down(xxl) {
    padding: 225px 0 100px;
  }

  @include media-breakpoint-down(xl) {
    padding: 200px 0 50px;
  }

  @include media-breakpoint-down(lg) {
    padding: 125px 0 0;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 20px 0 0;
    margin-bottom: 0;
  }

  .parallax-parent {
    &-img {
      bottom: 35%;
      width: 1189px;
      height: 793px;
      object-position: 10%;
    }

    &-container {
      width: 100%;
      max-width: 850px;
      height: 696px;
      clip-path: url(#svgPathExperts);
      right: 0;
      bottom: 0;
      z-index: -1;

      @include media-breakpoint-down(onek) {
        max-width: 755px;
        height: 618px;
      }

      @include media-breakpoint-down(xxxl) {
        max-width: 650px;
        height: 532px
      }

      @include media-breakpoint-down(xxl) {
        max-width: 550px;
        height: 450px
      }

      @include media-breakpoint-down(xl) {
        max-width: 450px;
        height: 368px
      }

      @include media-breakpoint-down(lg) {
        max-width: 350px;
        height: 286px
      }

      @include media-breakpoint-down(md) {
        position: relative;
        margin-top: 20px;
        max-width: 450px;
        height: 368px
      }

      @include media-breakpoint-down(sm) {
        max-width: 350px;
        height: 286px
      }
    }
  }

  .parallax-copy {
    line-height: .85;

    @include media-breakpoint-down(lg) {
      right: 15%;
    }

    span {
      left: 110px;
    }
  }

  &__col {
    &-rotated {
      @include media-breakpoint-between(md, lg) {
        margin-top: 150px;
      }
    }

    &-copy {
      .h2 {
        margin-bottom: 30px;
        max-width: 500px;
      }
    }
  }
}
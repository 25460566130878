.mmsi-attributes {
  &__col {
    &-left {
      .h2 {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          max-width: 290px;
        }
      }

      p {
        margin-bottom: 30px;
      }
    }

    &-right {
      img {
        aspect-ratio: 490/471;
        max-width: 490px;
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
    }
  }
}
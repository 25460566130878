.mmsi-header {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: .3s ease;
  background-color: rgba(255, 255, 255, 0.9);

  @include media-breakpoint-down(lg) {
    height: 75px;
  }

  .link-inherit {
    color: $c-dark-blue;
  }

  .mmsi-header-hamburger {
    border-color: $c-dark-blue;

    &:before,
    &:after {
      background-color: $c-dark-blue;
    }
  }

  &.active {
    .mmsi-header-hamburger {
      border-color: $c-dark-blue;

      &:before,
      &:after {
        background-color: $c-dark-blue;
      }

      &:before {
        top: 50%;
        transform: translateX(-50%) rotate(45deg);
      }

      &:after {
        top: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    .mmsi-header-nav {
      transform: translateX(0);
    }
  }

  &-hamburger {
    border: 2px solid $c-white;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 15px;
      height: 2px;
      background-color: $c-white;
      transition: 0.3s ease;
      left: 50%;
      top: 50%;
    }

    &:before {
      transform: translate(-50%, calc(-50% + -3px));
    }

    &:after {
      transform: translate(-50%, calc(-50% + 3px));
    }
  }

  &-logo {
    height: 61px;
  }

  &-nav {
    transition: .3s ease;

    &:not(.mmsi-header-nav-special) {
      @include media-breakpoint-down(lg) {
        background: $c-white;
        position: absolute;
        width: 100%;
        height: calc(100vh + 64px);
        left: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: -1;
        padding-top: 75px;
      }

      ul {
        li {
          @include media-breakpoint-down(lg) {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          a {
            @include media-breakpoint-down(lg) {
              color: $c-dark-blue;
              height: 68px;
              display: flex;
              align-items: center;
              width: 100%;
              padding-right: calc(var(--bs-gutter-x) * .5);
              padding-left: calc(var(--bs-gutter-x) * .5);
              font-size: 24px;
              text-decoration: none;

              &:hover {
                background-color: $c-orange;
                color: $c-white;
              }
            }

            &.active {
              text-decoration-color: inherit;
            }
          }
        }
      }
    }

    ul {
      list-style: none;

      li {
        &:not(:first-of-type) {
          margin-left: 30px;

          @include media-breakpoint-down(lg) {
            margin-left: 15px;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-left: 0 !important;
        }

        a {
          color: $c-white;
        }
      }
    }
  }
}
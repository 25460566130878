.mmsi-team {
  padding-top: 50px;
  margin-top: 110px;

  @include media-breakpoint-down(lg) {
    margin-top: 70px;
  }

  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }

  &__col {
    &-title {
      margin-bottom: 100px;

      @include media-breakpoint-down(lg) {
        margin-bottom: 50px;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }

      .h2 {
        margin-bottom: 30px;
      }
    }

    &-member {
      @include media-breakpoint-up(xxl) {
        &:nth-child(n+7) {
          a {
            padding-top: 50px;
          }
        }

        &:nth-of-type(6n+2) {
          a {
            margin-top: 30px;
          }
        }

        &:nth-of-type(6n+3) {
          a {
            margin-top: -30px;
          }
        }

        &:nth-of-type(6n+4) {
          a {
            margin-top: 15px;
          }
        }

        &:nth-of-type(6n+5) {
          a {
            margin-top: -15px;
          }
        }

        &:nth-of-type(6n+6) {
          a {
            margin-top: 10px;
          }
        }
      }

      @include media-breakpoint-between(lg, xxl) {
        &:nth-child(n+5) {
          a {
            padding-top: 50px;
          }
        }

        &:nth-of-type(4n+2) {
          a {
            margin-top: 30px;
          }
        }

        &:nth-of-type(4n+3) {
          a {
            margin-top: -30px;
          }
        }

        &:nth-of-type(4n+4) {
          a {
            margin-top: 15px;
          }
        }
      }

      @include media-breakpoint-between(md, lg) {
        &:nth-child(n+4) {
          a {
            padding-top: 50px;
          }
        }

        &:nth-of-type(3n+2) {
          a {
            margin-top: 30px;
          }
        }

        &:nth-of-type(3n+3) {
          a {
            margin-top: -30px;
          }
        }
      }

      @include media-breakpoint-down(md) {
        &:nth-child(n+3) {
          a {
            padding-top: 30px;
          }
        }

        &:nth-of-type(2n+2) {
          a {
            margin-top: 30px;
          }
        }
      }

      a {
        text-decoration: none;

        &:hover {
          picture {
            aspect-ratio: 105/109;

            img {
              transform: scale(1.1);
            }
          }
        }

        picture {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          border-radius: 24px;
          position: relative;
          overflow: hidden;

          img {
            transition: .3s ease;
            width: 100%;
            object-position: center;
            object-fit: cover;

            @include media-breakpoint-up(xxl) {
              height: 218px;
            }
          }
        }

        .h5 {
          font-size: 18px;
          line-height: 1;
          color: $c-black;
          font-weight: 400;
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          line-height: 1.29;
          margin-bottom: 5px;
          color: $c-black;

          &:last-of-type {
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
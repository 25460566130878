.blocker {
  z-index: 99999;
  padding-right: 12px;
  padding-left: 12px;
  background: rgba(0, 21, 35, 0.8);
  backdrop-filter: blur(2px);
  transition: .3s ease;

  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.modal {
  max-width: 1170px;
  width: 100%;
  padding: 120px;
  border-radius: 24px;

  @include media-breakpoint-down(lg) {
    padding: 60px;
  }

  @include media-breakpoint-down(md) {
    padding: 70px 15px 60px;
    border-radius: 0;
  }

  a.close-modal {
    background-image: url("../images/icon-close.svg");
    top: 40px;
    right: 40px;

    @include media-breakpoint-down(md) {
      top: 10px;
      right: 20px;
    }
  }

  &-team {
    &-title {
      margin-bottom: 50px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
      }

      picture {
        display: flex;
        align-items: center;

        img {
          border-radius: 24px;
          max-width: 210px;
          margin-right: 30px;

          @include media-breakpoint-down(md) {
            margin-right: 0;
            margin-bottom: 30px;
          }
        }
      }

      .h2 {
        font-size: 62px;
        line-height: .84;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          font-size: 40px;
        }
      }
    }

    p {
      line-height: 1.56;
    }
  }
}
.mmsi-copy-block {
  padding: 50px 0;
  margin: 30px 0;

  @include media-breakpoint-down(md) {
    margin: 0;
  }

  .h2 {
    font-style: normal;
    font-weight: 300;
    line-height: 1.87;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }
}